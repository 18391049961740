//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CrossSectionDrawing",
    props: ['data'],
    data() {
        return {
            assetPath: this.$config.pimcoreUrl
        };
    },
    methods: {
        getImageUrl(image) {
            return this.$mapImageData(image)
        }
    }
}
